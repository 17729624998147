.page-table .ant-table .ant-table-tbody > tr:hover > td {
  background: #41a6ff96;
}

.action-icon {
  font-size: 20px;
  opacity: 0.7;
  color: rgb(0, 142, 212);
}

.action-icon:hover {
  color: rgb(0, 65, 161);
}

.segment .page-header {
  border-bottom: 3px solid;
  padding-bottom: 10px;
  font-size: 15pt;
  text-align: center;
}

.page-inner-style {
  background: white;
  margin: 20px 0;
  padding: 8px 20px !important;
  text-align: left !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.apt-alter-row-0 {
  background: #d7f3ff;
}

.apt-alter-row-1 {
  background: white;
}
